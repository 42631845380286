@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Readex_Light";
  src: url("/public/fonts/ReadexPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Readex_Regular";
  src: url("/public/fonts/ReadexPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Readex_Medium";
  src: url("/public/fonts/ReadexPro-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Readex_ExtraLight";
  src: url("/public/fonts/ReadexPro-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Readex_SemiBold";
  src: url("/public/fonts/ReadexPro-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Readex_Bold";
  src: url("/public/fonts/ReadexPro-bold.ttf") format("truetype");
}

.bg-image {
  background-image: url("../src/images/service_bg.png");
  background-size: cover;
}

.faq-image {
  background-image: url("../src/images/faq_bg.png");
  background-size: cover;
}

.serviceBg {
  background-image: url("../src/images/service_img.png");
  background-size: cover;
}

.choiceBg {
  background-image: url("../src/images/service.png");
  background-size: cover;
}

.contactBg {
  background-image: url("../src/images/contact.png");
  background-size: cover;
}

.slick-dots {
  bottom: -60px !important;
}

.slick-arrow {
  display: none !important;
}

.slick-slide > div > div {
  outline: none !important;
}

.slick-dots li button:before {
  background-color: #5a65ff;
  width: 31px !important;
  height: 6px !important;
  content: "" !important;
  border-radius: 50px;
}
@media (max-width: 765px) {
  .slick-dots li button:before {
    width: 6px !important;
    height: 6px !important;
  }
}

.accordion-content {
  display: none;
}

.accordion-content.active {
  display: block;
}
.recharts-legend-item-text{
  color: black !important;
  font-size: 12px !important;
}
.recharts-layer .recharts-bar-rectangle{
border-top: 20px !important;
}
.graphdiv{
  border-radius: 40px;
background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FBFBFF 100%);
box-shadow: 0px -19px 48px 0px rgba(242, 236, 255, 0.40) inset;
}

.docdiv h1{
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0;
  text-transform: capitalize;
}
.docdiv h2{
  font-size: 18px;
  margin: 10px 0;
}
.docdiv h3,h4,h5,h6{
  font-size: 16px;
  /* font-weight: 600; */
  margin: 10px 0;
}
.docdiv p > strong{
  text-transform: uppercase;
}
.docdiv p{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0;
}
.docdiv p > a::after {
  content: "";
}


.docdiv p>a:hover{
 text-decoration: underline;
}
.docdiv ul{
 list-style: disc;
 margin: 10px 0;
}
.docdiv ul>li{
 margin: 10px 0;
}
@media (max-width: 640px) {
  .docdiv p {
    font-size: 16px; /* Reduce font size */
    gap: 10px;     
    display: flex;
    flex-wrap: nowrap;
  }
}
@media (max-width: 640px) {
  .docdiv p > a::after {
    content: "";
  }
}
